export const mySource = {
  mySourceStatusOpen: 'MySource-status-open',
  mySourceStatusClose: 'MySource-status-close',
  mySourceSearch: 'MySource-searchSource',
  mySourceBookmarkOpen: 'MySource-bookmark-open',
  // status
  mySourceStatusAllSelected: 'MySource-statusAll-selected',
  mySourceStatusAllUnselect: 'MySource-statusAll-unselect',
  mySourceStatusonlineSelected: 'MySource-statusOnline-selected',
  mySourceStatusonlineUnselect: 'MySource-statusOnline-unselect',
  mySourceStatusnoCameraSelected: 'MySource-statusNoCamera-selected',
  mySourceStatusnoCameraUnselect: 'MySource-statusNoCamera-unselect',
  mySourceStatuslivingSelected: 'MySource-statusLiving-selected',
  mySourceStatuslivingUnselect: 'MySource-statusLiving-unselect',
  mySourceStatusofflineSelected: 'MySource-statusOffline-selected',
  mySourceStatusofflineUnselect: 'MySource-statusOffline-unselect',
  // type
  mySourceTypeAllSelected: 'MySource-typeAll-selected',
  mySourceTypeAllUnselect: 'MySource-typeAll-unselect',
  mySourceTypepackSelected: 'MySource-typePack-selected',
  mySourceTypepackUnselect: 'MySource-typePack-unselect',
  mySourceTypeanywhereSelected: 'MySource-typeAnywhere-selected',
  mySourceTypeanywhereUnselect: 'MySource-typeAnywhere-unselect',
  mySourceTypegridPackSelected: 'MySource-typeGridPack-selected',
  mySourceTypegridPackUnselect: 'MySource-typeGridPack-unselect',
  mySourceTypegridSdiSelected: 'MySource-typeGridSdi-selected',
  mySourceTypegridSdiUnselect: 'MySource-typeGridSdi-unselect',
  mySourceTypeextSelected: 'MySource-typeExt-selected',
  mySourceTypeextUnselect: 'MySource-typeExt-unselect',
  mySourceTypegridViaTokenSelected: 'MySource-typeGridViaToken-selected',
  mySourceTypegridViaTokenUnselect: 'MySource-typeGridViaToken-unselect',
  mySourceTypeglobalGridSelected: 'MySource-typeGlobalGrid-selected',
  mySourceTypeglobalGridUnselect: 'MySource-typeGlobalGrid-unselect',
  // sort
  mySourceSortonlineFirst: 'MySource-sortOnlineFirst',
  mySourceSortliveFirst: 'MySource-sortLiveFirst',
  mySourceSortnameFirst: 'MySource-sortNameFirst',
  // add btn
  mySourceAddExtOpen: 'MySource-addExt-open',
  mySourceAddExtClose: 'MySource-addExt-close',
  mySourceSaveExt: 'MySource-saveExt',
  // selected ext type
  mySourceAddExtTypeExt: 'MySource-addExtTypeExt',
  mySourceAddExtTypeSMPTE2022: 'MySource-addExtTypeSMPTE2022',
  mySourceAddExtTypeYouTube: 'MySource-addExtTypeYouTube',
  mySourceAddExtTypeGridToken: 'MySource-addExtTypeGridToken',
  //
  mySourceSourceContent: 'MySource-jumpSourceContent',
  mySourceSourceList: 'MySource-jumpSourceList',
  mySourceSourceMap: 'MySource-jumpSourceMap',
  // live or stop
  mySourceBtnLive: 'MySource-takeBtn-live',
  mySourceBtnStop: 'MySource-stopBtn-stop',
  // config-T
  mySourceConfitT: 'MySource-configT',
  // setting box
  mySourceSelect: 'MySource-select-model',
  mySourceSpeed: 'MySource-selectQuality-6',
  mySourceDynamic: 'MySource-setDelay-dynamic',
  mySourceFixed: 'MySource-setDelay-fixed',
  mySourcevbr: 'MySource-setBitrate-vbr',
  mySourcecbr: 'MySource-setBitrate-cbr',
  // set model
  mySourceModelInterview: 'MySource-model-interview',
  mySourceModelNormal: 'MySource-model-normal',
  mySourceModelFastMoving: 'MySource-model-fastMoving',
  mySourceModelSD: 'MySource-model-sd',
  mySourceModelTapeFeed: 'MySource-model-tapeFeed',
  mySourceModelUser1: 'MySource-model-user1',
  mySourceModelUser2: 'MySource-model-user2retretret',
  // media
  mySourceOpenShare: 'MySource-share-open',
  mySourceCloseShare: 'MySource-share-close',
  // speed
  'mySourceSpeed1.2Mb/s': 'MySource-searchSource-1.2Mb/s',
  'mySourceSpeed2.4Mb/s': 'MySource-searchSource-2.4Mb/s',
  'mySourceSpeed6Mb/s': 'MySource-searchSource-6Mb/s',
  'mySourceSpeed12Mb/s': 'MySource-searchSource-12Mb/s',
  'mySourceSpeed24Mb/s': 'MySource-searchSource-24Mb/s',
  'mySourceSpeed30Mb/s': 'MySource-searchSource-30Mb/s',
  'mySourceSpeed50Mb/s': 'MySource-searchSource-50Mb/s',
  'mySourceSpeed100Mb/s': 'MySource-searchSource-100Mb/s',
  'mySourceSpeed120Mb/s': 'MySource-searchSource-120Mb/s',
  'mySourceSpeed150Mb/s': 'MySource-searchSource-150Mb/s',
  'mySourceSpeed180Mb/s': 'MySource-searchSource-180Mb/s',
  // voip
  mySourceVoipStart: 'MySource-voip-start',
  mySourceVoipStop: 'MySource-voip-stop',
  // video
  mySourceVideoClose: 'MySource-video-close',
  mySourceVideoOpen: 'MySource-video-open',
  // anywhere camera相关
  mySourceOpenCamera: 'MySource-AnywhereCamera-open',
  mySourceCloseCamera: 'MySource-AnywhereCamera-close',
  mySourceCameraManual: 'MySource-AnywhereCamera-manual',
  mySourceCameraAuto: 'MySource-AnywhereCamera-auto',
  mySourceCameraReset: 'MySource-AnywhereCamera-reset',
  mySourceGotoMap: 'MySource-LocationMap-open',
  mySourceChangeLarge: 'MySource-ChangeLargeSize',
  mySourceChangeMedium: 'MySource-ChangeMediumSize',
  mySourceChangeSmall: 'MySource-ChangeSmallSize',
  /*
   * tab表格中的用到         bitrate: true,
   *                     latency: true,
   *                     format: true,
   *                     battery: true,
   *                     bookmark: true,
   *                     typeVal: true,
   *                     version: true,
   *                     location: true
   */
  mySourceTableBitrate: 'MySource-Table-bitrate',
  mySourceTableLatency: 'MySource-Table-latency',
  mySourceTableFormat: 'MySource-Table-format',
  mySourceTableBattery: 'MySource-Table-battery',
  mySourceTableBookmark: 'MySource-Table-bookmark',
  mySourceTableTypeVal: 'MySource-Table-type',
  mySourceTableVersion: 'MySource-Table-version',
  mySourceTableLocation: 'MySource-Table-location',
  // 表格比特率延迟显示
  mySourceTableBiterShow: 'MySource-Table-BiterShow',
  mySourceTableSetBiterVbr: 'MySource-Table-SetBiterVbr',
  mySourceTableSetBiterCbr: 'MySource-Table-SetBiterCbr',
  mySourceTableSetBiterVal: 'MySource-Table-SetBiterVal',
  mySourceTableDelayShow: 'MySource-Table-DelayShow',
  mySourceTableSetDelayDynamic: 'MySource-Table-SetDelayDynamic',
  mySourceTableSetDelayFixed: 'MySource-Table-SetDelayFixed',
  mySourceTableSetDelayVal: 'MySource-Table-SetDelayVal',
  mySourceTableShowBookmark: 'MySource-Table-ShowBookmark',
  mySourceTableCloseBookmark: 'MySource-Table-CloseBookmark',
  mySourceTableShowVideo: 'MySource-Table-ShowVideo',
  mySourceTableSelectR: 'MySource-Table-SelectR',
  mySourceTableDragColumn: 'MySource-Table-DragColumn',
  mySourceTableColumnStretch: 'MySource-Table-ColumnStretch',
  mySourceTableBookmarkSave: 'MySource-Table-BookmarkSave'
}

export const advancedControl = {
  // peerlist
  adSourceList: 'AdvancedControl-RList-show',
  adSearch: 'AdvancedControl-searchSource',
  // status
  adStatuslivingOpen: 'AdvancedControl-statusLive-open',
  adStatuslivingClose: 'AdvancedControl-statusLive-close',
  adStatusonlineOpen: 'AdvancedControl-statusOnline-open',
  adStatusonlineClose: 'AdvancedControl-statusOnline-close',
  adStatusofflineOpen: 'AdvancedControl-statusOffline-open',
  adStatusofflineClose: 'AdvancedControl-statusOffline-close',
  // type
  adTypeallOpen: 'AdvancedControl-typeAll-open',
  adTypeallClose: 'AdvancedControl-typeAll-close',
  adTypepackOpen: 'AdvancedControl-typePack-open',
  adTypepackClose: 'AdvancedControl-typePack-close',
  adTypeanywhereOpen: 'AdvancedControl-typeAnywhere-open',
  adTypeanywhereClose: 'AdvancedControl-typeAnywhere-close',
  adTypegridPackOpen: 'AdvancedControl-typeGridPack-open',
  adTypegridPackClose: 'AdvancedControl-typeGridPack-close',
  adTypegridSdiOpen: 'AdvancedControl-typeGridSDI-open',
  adTypegridSdiClose: 'AdvancedControl-typeGridSDI-close',
  adTypeextOpen: 'AdvancedControl-typeExt-open',
  adTypeextClose: 'AdvancedControl-typeExt-close',
  // sort
  adSortonlineFirst: 'AdvancedControl-onlineFirst',
  adSortliveFirst: 'AdvancedControl-liveFirst',
  adSortnameFirst: 'AdvancedControl-nameFirst',

  // list
  adListThumbnailList: 'AdvancedControl-thumbnailList',
  adListSourceList: 'AdvancedControl-sourceList',
  adListSelectSource: 'AdvancedControl-sourceThumbnail-select',

  // addVideo Dialog
  adDialogVideoOpen: 'AdvancedControl-videoType-open',
  adDialogVideoClose: 'AdvancedControl-videoType-close',
  adDialogExtOpen: 'AdvancedControl-addExt-open',
  adDialogExtClose: 'AdvancedControl-addExt-close',
  adDialogExtSave: 'AdvancedControl-addExt-save',
  adDialogSMPTE2022Open: 'AdvancedControl-addSMPTE2022-open',
  adDialogSMPTE2022Close: 'AdvancedControl-addSMPTE2022-close',
  adDialogSMPTE2022Save: 'AdvancedControl-addSMPTE2022-save',
  adDialogYouTubeOpen: 'AdvancedControl-addYoutube-open',
  adDialogYouTubeClose: 'AdvancedControl-addYoutube-close',
  adDialogYouTubeSave: 'AdvancedControl-addYoutube-save',
  adDialogYouTubeValidate: 'AdvancedControl-Youtube-validate',

  // Downloaded Files Dialog
  adDialogDownloadOpen: 'AdvancedControl-downloadedFiles-open',
  adDialogDownloadClose: 'AdvancedControl-downloadedFiles-close',
  adDialogDownloadDelete: 'AdvancedControl-downloadedFiles-delete',
  adDialogDownloadDeleteAll: 'AdvancedControl-downloadedFiles-deleteAll',

  // video区域
  adVideoSelectSource: 'AdvancedControl-videoSource-select',
  adVideoTake: 'AdvancedControl-video-take',
  adVideoStop: 'AdvancedControl-video-stop',

  adVideoInterview: 'AdvancedControl-video-Interview',
  adVideoNormal: 'AdvancedControl-video-Normal',
  adVideoFastMoving: 'AdvancedControl-video-FastMoving',
  adVideoSD: 'AdvancedControl-video-SD',
  adVideoTapeFeed: 'AdvancedControl-video-TapeFeed',
  adVideoUser1: 'AdvancedControl-video-User1',
  adVideoUser2retretret: 'AdvancedControl-video-User2retretret',
  // bit
  'adVideo1.2Mb/s': 'AdvancedControl-video-1.2Mb/s',
  'adVideo2.4Mb/s': 'AdvancedControl-video-2.4Mb/s',
  'adVideo6Mb/s': 'AdvancedControl-video-6Mb/s',
  'adVideo12Mb/s': 'AdvancedControl-video-12Mb/s',
  'adVideo24Mb/s': 'AdvancedControl-video-24Mb/s',
  'adVideo30Mb/s': 'AdvancedControl-video-30Mb/s',
  'adVideo50Mb/s': 'AdvancedControl-video-50Mb/s',
  'adVideo100Mb/s': 'AdvancedControl-video-100Mb/s',
  'adVideo120Mb/s': 'AdvancedControl-video-120Mb/s',
  'adVideo150Mb/s': 'AdvancedControl-video-150Mb/s',
  'adVideo180Mb/s': 'AdvancedControl-video-180Mb/s',
  // live
  adLiveTab: 'AdvancedControl-liveTab',
  adLiveFlowOpen: 'AdvancedControl-liveFlow-open',
  adLiveFlowClose: 'AdvancedControl-liveFlow-close',
  adLiveConfigT: 'AdvancedControl-liveConfigT',
  adLiveReset: 'AdvancedControl-liveReset',
  'adLive1.2Mb/s': 'AdvancedControl-live-1.2Mb/s',
  'adLive2.4Mb/s': 'AdvancedControl-live-2.4Mb/s',
  'adLive6Mb/s': 'AdvancedControl-live-6Mb/s',
  'adLive12Mb/s': 'AdvancedControl-live-12Mb/s',
  'adLive24Mb/s': 'AdvancedControl-live-24Mb/s',
  'adLive30Mb/s': 'AdvancedControl-live-30Mb/s',
  'adLive50Mb/s': 'AdvancedControl-live-50Mb/s',
  'adLive100Mb/s': 'AdvancedControl-live-100Mb/s',
  'adLive120Mb/s': 'AdvancedControl-live-120Mb/s',
  'adLive150Mb/s': 'AdvancedControl-live-150Mb/s',
  'adLive180Mb/s': 'AdvancedControl-live-180Mb/s',

  // record
  adRecordTab: 'AdvancedControl-recordTab',
  adRecordLastWeek: 'AdvancedControl-recordLastWeek',
  adRecordNextWeek: 'AdvancedControl-recordNextWeek',
  adRecordDay: 'AdvancedControl-recordDay',
  adRecordDownloadAll: 'AdvancedControl-record-downloadAll',
  adRecordPreview: 'AdvancedControl-record-preview',
  adRecordDownload: 'AdvancedControl-record-download',
  adRecordFileDownload: 'AdvancedControl-recordFile-download',
  adRecordDownloadPlay: 'AdvancedControl-recordDownload-play',
  adRecordFTPPlay: 'AdvancedControl-recordFTP-play',
  adRecordDownloadDelete: 'AdvancedControl-recordDownload-delete',
  adRecordFTPDelete: 'AdvancedControl-recordFTP-delete',
  adRecordFileDownloadAll: 'AdvancedControl-recordFile-downloadAll',
  adRecordFTPDownloadAll: 'AdvancedControl-recordFTP-downloadAll',
  adRecordDownloadModule: 'AdvancedControl-recordDownloadModule',
  adRecordFTPModule: 'AdvancedControl-recordFTPModule',

  // setting
  adSettingOpen: 'AdvancedControl-setting-open',
  adSettingClose: 'AdvancedControl-setting-close',
  adSettingbase: 'AdvancedControl-setting-basic',
  adSettinglocalAllPack: 'AdvancedControl-setting-localAllPack',
  adSettingfeatureOpen: 'AdvancedControl-settingFeature-open',
  adSettingfeatureClose: 'AdvancedControl-settingFeature-close',
  adSettinggridEncoderOpen: 'AdvancedControl-settingGridEncoder-open',
  adSettinggridEncoderClose: 'AdvancedControl-settingGridEncoder-close',
  adSettingsmartCaptionOpen: 'AdvancedControl-settingSmartCaption-open',
  adSettingsmartCaptionClose: 'AdvancedControl-settingSmartCaption-close',
  adSettingsmartCaptionSave: 'AdvancedControl-settingSmartCaption-save',
  adSettingsdiOpen: 'AdvancedControl-settingSDI-open',
  adSettingsdiClose: 'AdvancedControl-settingSDI-close',
  adSettingvisionTagOpen: 'AdvancedControl-settingVisionTag-open',
  adSettingvisionTagClose: 'AdvancedControl-settingVisionTag-close',
  adSettingvisionTagSave: 'AdvancedControl-settingVisionTag-save',
  adSettingautoRecordOpen: 'AdvancedControl-settingAutoRecord-open',
  adSettingautoRecordClose: 'AdvancedControl-settingAutoRecord-close',

  adSettingVLANSettingOpen: 'AdvancedControl-settingVLANSetting-open',
  adSettingVLANSettingClose: 'AdvancedControl-settingVLANSetting-close',
  adSettingTokenServiceOpen: 'AdvancedControl-settingTokenService-open',
  adSettingTokenServiceClose: 'AdvancedControl-settingTokenService-close',
  adSettingipStreamOutOpen: 'AdvancedControl-settingipStreamOut-open',
  adSettingipStreamOutClose: 'AdvancedControl-settingipStreamOut-close',
  adSettingTokenServiceCreate: 'AdvancedControl-settingTokenService-Create',
  adSettingTokenServiceSetCkeckToken:
    'AdvancedControl-settingTokenServiceSet-CkeckToken',
  adSettingTokenServiceSetSetToken:
    'AdvancedControl-settingTokenServiceSet-SetToken',
  adSettingipStreamOutPrefileAdd:
    'AdvancedControl-settingipStreamOutPrefile-Add',
  adSettingVLANSettingRefresh: 'AdvancedControl-settingVLANSetting-Refresh',
  adSettingVLANSettingStopInterface:
    'AdvancedControl-settingVLANSetting-StopInterface',
  adSettingVLANSettingApply: 'AdvancedControl-settingVLANSetting-Apply',
  adSettingVLANSettingRightRefresh:
    'AdvancedControl-settingVLANSetting-RightRefresh',

  adSettinautoDownloadOpen: 'AdvancedControl-settingAutoDownload-open',
  adSettinautoDownloadClose: 'AdvancedControl-settingAutoDownload-close',
  adSettingprogressDownloadOpen: 'AdvancedControl-settingProgressDownload-open',
  adSettingprogressDownloadClose:
    'AdvancedControl-settingProgressDownload-close',
  adSettinglocationOpen: 'AdvancedControl-settingLocation-open',
  adSettinglocationClose: 'AdvancedControl-settingLocation-close',
  adSettingipAndPortOpen: 'AdvancedControl-settingIpAndPort-open',
  adSettingipAndPortClose: 'AdvancedControl-settingIpAndPort-close',
  adSettingipAndPortSave: 'AdvancedControl-settingIpAndPort-save',
  adSettingmediaOpen: 'AdvancedControl-settingMedia-open',
  adSettingmediaClose: 'AdvancedControl-settingMedia-close',
  adSettingmediaSave: 'AdvancedControl-settingMedia-save',

  // Rlist
  adRList: 'AdvancedControl-RTab-select',
  adSelectR: 'AdvancedControl-R-select',
  adRSearch: 'AdvancedControl-R-search',
  // status
  adRStatuslivingOpen: 'AdvancedControl-RstatusLive-open',
  adRStatuslivingClose: 'AdvancedControl-RstatusLive-close',
  adRStatusonlineOpen: 'AdvancedControl-RstatusOnline-open',
  adRStatusonlineClose: 'AdvancedControl-RstatusOnline-close',
  adRStatusofflineOpen: 'AdvancedControl-RstatusOffline-open',
  adRStatusofflineClose: 'AdvancedControl-RstatusOffline-close',
  // sort
  adRSortonlineFirst: 'AdvancedControl-R-onlineFirst',
  adRSortliveFirst: 'AdvancedControl-R-liveFirst',
  adRSortnameFirst: 'AdvancedControl-R-nameFirst',
  // stop
  adRStop: 'AdvancedControl-R-stop',
  // multi-control
  showMultiControlRC: 'AdvanceControl-showMultiControl',
  setTimeLockRC: 'AdvanceControl-setTimelock',
  stopTimeLockRC: 'AdvanceControl-stopTimelock',
  setTimeLockBitrateBySliderRC:
    'AdvanceControl-multiContorl-setBitrate-bySilder',
  setTimeLockDelayBySilderRC: 'AdvanceControl-multiContorl-setDelay-bySilder',
  setTimeLockBitrateByInputRC: 'AdvanceControl-multiContorl-setBitrate-byInput',
  setTimeLockDelayByInputRC: 'AdvanceControl-multiContorl-setDelay-byInput',
  selectTimeLockReceiverByDropRC:
    'AdvanceControl-multiContorl-selectReceiver-byDrop',
  cancelSelectTimeLockReceiverByDropRC:
    'AdvanceControl-multiContorl-cancelSelectReceiver-byDrop',
  cancelSelectTimLockReceiverByClickBtnRC:
    'AdvanceControl-multiContorl-cancelSelectReceiver-byClickBtn',
  showWebPageRC: 'AdvanceControl-multiContorl-showWebR',
  showReceiverInfoRC: 'AdvanceControl-multiContorl-showReceiverInfo',
  clickMuteButtonRC: 'AdvanceControl-multiContorl-clickMuteButton',
  clickVoiceButtonRC: 'AdvanceControl-multiContorl-clickVoiceButton',
  selectSourceRC: 'AdvanceControl-multiContorl-clickVoiceButton',
  clickStartButtonRC: 'AdvanceControl-multiContorl-startLive',
  clickStopButtonRC: 'AdvanceControl-multiContorl-stopLive',
  closeMultiControlRC: 'AdvanceControl-multiContorl-close',
  // ip stream out
  enableEncoderRC: 'AdvanceControl-ipStreamOut-encoderEnable',
  disableEncoderRC: 'AdvanceControl-ipStreamOut-encoderDisable',
  enableCbrRC: 'AdvanceControl-ipStreamOut-cbrEnable',
  disableCbrRC: 'AdvanceControl-ipStreamOut-cbrDisable',
  setResolutionRC: 'AdvanceControl-ipStreamOut-setResolution',
  setBitrateRC: 'AdvanceControl-ipStreamOut-setBitrate',
  setABitrateRC: 'AdvanceControl-ipStreamOut-setABitrate',
  setAChannelRC: 'AdvanceControl-ipStreamOut-setAChannel',
  setCodecRC: 'AdvanceControl-ipStreamOut-setCodec',
  setCopierRC: 'AdvanceControl-ipStreamOut-setFileCopier',
  setCopierHM: 'Home-ipStreamOut-setFileCopier',
  addNewShareAccountRC: 'AdvanceControl-ipStreamOut-addNewShareAccount',
  startPushStreamRC: 'AdvanceControl-ipStreamOut-pushStream',
  stopPushStreamRC: 'AdvanceControl-ipStreamOut-stopPushStream',
  uploadLogoRC: 'AdvanceControl-ipStreamOut-uploadLogo',
  SharedMemoryRC: 'AdvanceControl-ipStreamOut-SharedMemory',

  // media
  adOpenShare: 'AdvancedControl-share-open',
  adCloseShare: 'AdvancedControl-share-close'
}

export const myReceivers = {
  myReceiversMuteVolumeOff: 'MyReceivers-muteVolume-off',
  myReceiversMuteVolumeOn: 'MyReceivers-muteVolume-on',
  myReceiversVideoSetClose: 'MyReceivers-setVideoSetup-close', // 显示延迟设置 无
  myReceiversVideoSetOpen: 'MyReceivers-setVideoSetup-open', // 显示延迟设置 无
  myReceiversVideoParameterOpen: 'MyReceivers-viewVideoParameter-open',
  myReceiversVideoParameterClose: 'MyReceivers-viewVideoParameter-close',
  myReceiversFavoriteYes: 'MyReceivers-addFavorite-yes',
  myReceiversFavoriteNo: 'MyReceivers-addFavorite-no',
  myReceiversSearchDestination: 'MyReceivers-searchDestination',
  myReceiversSearchSource: 'MyReceivers-searchSource', // 查询T的类型 在ncc内无
  myReceiversPreviewVideoClose: 'MyReceivers-previewVideo-close',
  myReceiversPreviewVideoOpen: 'MyReceivers-previewVideo-open',
  myReceiversPreviewVideoDown: 'MyReceivers-previewVideo-Down', // 点击眼睛 在ncc无 现修改成展开下拉
  myReceiversPreviewVideoUp: 'MyReceivers-previewVideo-Up', // 点击眼睛 在ncc无 现修改成关闭下拉
  myReceiversMemoSave: 'MyReceivers-memo-save',
  myReceiversMemoFocus: 'MyReceivers-memo-focus',
  // media
  myReceiversOpenShare: 'MyReceivers-share-open',
  myReceiversCloseShare: 'MyReceivers-share-close',
  /*
   * 筛选R条件的谷歌统计
   *
   */
  myReceiversFilterAllSelected: 'MyReceivers-filterAll-selected',
  myReceiversFilterAllUnselect: 'MyReceivers-filterAll-unselect',
  myReceiversFilterLiveSelected: 'MyReceivers-filterLive-selected',
  myReceiversFilterLiveUnselect: 'MyReceivers-filterLive-unselect',
  myReceiversFilterOnlineSelected: 'MyReceivers-filterOnline-selected',
  myReceiversFilterOnlineUnselect: 'MyReceivers-filterOnline-unselect',
  myReceiversFilterOfflineSelected: 'MyReceivers-filterOffline-selected',
  myReceiversFilterOfflineUnselect: 'MyReceivers-filterOffline-unselect',
  myReceiversFilterFavoriteSelected: 'MyReceivers-filterFavorite-selected',
  myReceiversFilterFavoriteUnselect: 'MyReceivers-filterFavorite-unselect',
  myReceiversFilterSortSelected: 'MyReceivers-filterSort-selected',
  myReceiversFilterSortUnselect: 'MyReceivers-filterSort-unselect',
  /*
   * 筛选R条件的谷歌统计结束
   *
   */
  myReceiversNavPagePrevious: 'MyReceivers-navPagePrevious',
  myReceiversNavPageNext: 'MyReceivers-navPageNext',
  myReceiverGotoMap: 'MyReceiver-LocationMap-open',
  // 8.25新增功能添加到分析
  myRecevierChangeLarge: 'MyReceiver-ChangeLargeSize',
  myRecevierChangeMedium: 'MyReceiver-ChangeMediumSize',
  myRecevierChangeSmall: 'MyReceiver-ChangeSmallSize',
  myRecevierSearchSource: 'MyReceiver-searchSource',
  myRecevierselectSource: 'MyReceiver-selectSource'
}

export const booking = {
  pageName: 'Booking',
  bookingTableOpen: function () {
    return `${this.pageName}-table-open`
  },
  bookingCalendarOpen: function () {
    return `${this.pageName}-calendar-open`
  },
  bookingScteOpen: function () {
    return `${this.pageName}-scte-open`
  },
  bookingViewScheduleOpen: function () {
    return `${this.pageName}-viewSchedule-open`
  },
  bookingRunningBookingsOpen: function () {
    return `${this.pageName}-runningBookings-open`
  },
  bookingPastBookingsOpen: function () {
    return `${this.pageName}-pastBookings-open`
  },
  bookingCancelledBookingsOpen: function () {
    return `${this.pageName}-cancelledBookings-open`
  },
  bookingVideoLive: function () {
    return `${this.pageName}-previewVideo-open`
  },
  bookingVideoClose: function () {
    return `${this.pageName}-previewVideo-close`
  },
  bookingCreateEventSave: function () {
    return `${this.pageName}-createEvent-save`
  },
  bookingCreateScteAdd: function () {
    return `${this.pageName}-createScte-add`
  },
  bookingCreateEventclose: function () {
    return `${this.pageName}-createEvent-close`
  },
  bookingCreateScteclose: function () {
    return `${this.pageName}-createScte-close`
  },
  bookingCreateBoxClose: function () {
    return `${this.pageName}-createBooking-close`
  },
  bookingCreateBoxOpen: function () {
    return `${this.pageName}-createBooking-open`
  },
  bookingDuplicateBoxOpen: function () {
    return `${this.pageName}-duplicateBox-open`
  },
  bookingCalendarEventDetailBoxOpen: function () {
    return `${this.pageName}-calendarEventDetailBox-open`
  },
  bookingCalendarCreateBoxOpen: function () {
    return `${this.pageName}-calendarCreateBox-open`
  },
  bookingTableEventEditOpen: function () {
    return `${this.pageName}-tableEventEdit-open`
  },
  bookingCalendarEventEditOpen: function () {
    return `${this.pageName}-calendarEventEdit-open`
  },
  bookingCalendarEventEditShow: function () {
    return `${this.pageName}-calendarEventEdit-show`
  },
  bookingTableEventEditClose: function () {
    return `${this.pageName}-tableEventEdit-close`
  },
  bookingCalendarEventEditClose: function () {
    return `${this.pageName}-calendarEventEditOrCreate-close`
  },
  bookingTableEventEditSave: function () {
    return `${this.pageName}-tableEventEdit-save`
  },
  bookingCalendarEventEditSave: function () {
    return `${this.pageName}-calendarEventEdit-save`
  },
  bookingTableEventLiveEnd: function () {
    return `${this.pageName}-tableEventLive-end`
  },
  bookingCalendarEventLiveEnd: function () {
    return `${this.pageName}-calendarEventLive-end`
  },
  bookingTableEventDelete: function () {
    return `${this.pageName}-tableEvent-delete`
  },
  bookingCalendarEventDelete: function () {
    return `${this.pageName}-calendarEvent-delete`
  },
  bookingCalendarFilterIconClick: function () {
    return `${this.pageName}-calendarFilterBox-open`
  },
  bookingSourceConditionClick: function () {
    return `${this.pageName}-sourceConditionBox-open`
  },
  bookingRConditionClick: function () {
    return `${this.pageName}-rConditionBox-open`
  },
  bookingBookmarkClick: function () {
    return `${this.pageName}-bookmarkList-open`
  },
  bookingfilterClick: function () {
    return `${this.pageName}-filterList-open`
  },
  bookingDatePreClick: function () {
    return `${this.pageName}-datePre-click`
  },
  bookingDateNextClick: function () {
    return `${this.pageName}-dateNext-click`
  },
  bookingDateFilterClick: function () {
    return `${this.pageName}-dateFilter-click`
  },
  bookingLiveEventPlayClose: function () {
    return `${this.pageName}-liveEventPlay-close`
  },
  bookingLiveEventPlayOpen: function () {
    return `${this.pageName}-liveEventPlay-open`
  },
  bookingZoomOpen: function () {
    return `${this.pageName}-zoom-open`
  },
  bookingZoomClose: function () {
    return `${this.pageName}-zoom-close`
  },
  bookingZoomCreatedEventSave: function () {
    return `${this.pageName}-zoomCreatedEvent-save`
  },
  bookingZoomCreatedBoxClose: function () {
    return `${this.pageName}-zoomCreatedBox-close`
  },
  bookingZoomCreatedBoxOpen: function () {
    return `${this.pageName}-zoomCreatedBox-open`
  },
  bookingZoomColorOpen: function () {
    return `${this.pageName}-zoomColor-open`
  },
  bookingZoomColorChange: function () {
    return `${this.pageName}-zoomColor-change`
  },
  bookingEventColorChange: function () {
    return `${this.pageName}-eventColor-Change`
  },
  bookingEventColorOpen: function () {
    return `${this.pageName}-eventColor-open`
  }
}
export const playout = {
  pageName: 'playout',
  playoutTableOpen: function () {
    return `${this.pageName}-table-open`
  },
  playoutCalendarOpen: function () {
    return `${this.pageName}-calendar-open`
  },
  playoutScteOpen: function () {
    return `${this.pageName}-scte-open`
  },
  playoutViewScheduleOpen: function () {
    return `${this.pageName}-viewSchedule-open`
  },
  playoutRunningBookingsOpen: function () {
    return `${this.pageName}-runningBookings-open`
  },
  playoutPastBookingsOpen: function () {
    return `${this.pageName}-pastBookings-open`
  },
  playoutCancelledBookingsOpen: function () {
    return `${this.pageName}-cancelledBookings-open`
  },
  playoutVideoLive: function () {
    return `${this.pageName}-previewVideo-open`
  },
  playoutVideoClose: function () {
    return `${this.pageName}-previewVideo-close`
  },
  playoutCreateEventSave: function () {
    return `${this.pageName}-createEvent-save`
  },
  playoutCreateScteAdd: function () {
    return `${this.pageName}-createScte-add`
  },
  playoutCreateEventclose: function () {
    return `${this.pageName}-createEvent-close`
  },
  playoutCreateScteclose: function () {
    return `${this.pageName}-createScte-close`
  },
  playoutCreateBoxClose: function () {
    return `${this.pageName}-createBooking-close`
  },
  playoutCreateBoxOpen: function () {
    return `${this.pageName}-createBooking-open`
  },
  playoutDuplicateBoxOpen: function () {
    return `${this.pageName}-duplicateBox-open`
  },
  playoutCalendarEventDetailBoxOpen: function () {
    return `${this.pageName}-calendarEventDetailBox-open`
  },
  playoutCalendarCreateBoxOpen: function () {
    return `${this.pageName}-calendarCreateBox-open`
  },
  playoutTableEventEditOpen: function () {
    return `${this.pageName}-tableEventEdit-open`
  },
  playoutScteEditOpen: function () {
    return `${this.pageName}-scteEdit-open`
  },
  playoutCalendarEventEditOpen: function () {
    return `${this.pageName}-calendarEventEdit-open`
  },
  playoutCalendarEventEditShow: function () {
    return `${this.pageName}-calendarEventEdit-show`
  },
  playoutTableEventEditClose: function () {
    return `${this.pageName}-tableEventEdit-close`
  },
  playoutScteEditClose: function () {
    return `${this.pageName}-scteEdit-close`
  },
  playoutCalendarEventEditClose: function () {
    return `${this.pageName}-calendarEventEditOrCreate-close`
  },
  playoutTableEventEditSave: function () {
    return `${this.pageName}-tableEventEdit-save`
  },
  playoutScteEditSave: function () {
    return `${this.pageName}-scteEdit-save`
  },
  playoutCalendarEventEditSave: function () {
    return `${this.pageName}-calendarEventEdit-save`
  },
  playoutTableEventLiveEnd: function () {
    return `${this.pageName}-tableEventLive-end`
  },
  playoutCalendarEventLiveEnd: function () {
    return `${this.pageName}-calendarEventLive-end`
  },
  playoutTableEventDelete: function () {
    return `${this.pageName}-tableEvent-delete`
  },
  playoutScteDelete: function () {
    return `${this.pageName}-scte-delete`
  },
  playoutCalendarEventDelete: function () {
    return `${this.pageName}-calendarEvent-delete`
  },
  playoutCalendarFilterIconClick: function () {
    return `${this.pageName}-calendarFilterBox-open`
  },
  playoutSourceConditionClick: function () {
    return `${this.pageName}-sourceConditionBox-open`
  },
  playoutRConditionClick: function () {
    return `${this.pageName}-rConditionBox-open`
  },
  playoutBookmarkClick: function () {
    return `${this.pageName}-bookmarkList-open`
  },
  playoutfilterClick: function () {
    return `${this.pageName}-filterList-open`
  },
  playoutDatePreClick: function () {
    return `${this.pageName}-datePre-click`
  },
  playoutDateNextClick: function () {
    return `${this.pageName}-dateNext-click`
  },
  playoutDateFilterClick: function () {
    return `${this.pageName}-DateFilter-click`
  },
  playoutScteDateFilterClick: function () {
    return `${this.pageName}-scteDateFilter-click`
  },
  playoutLiveEventPlayClose: function () {
    return `${this.pageName}-liveEventPlay-close`
  },
  playoutLiveEventPlayOpen: function () {
    return `${this.pageName}-liveEventPlay-open`
  },
  playoutZoomOpen: function () {
    return `${this.pageName}-zoom-open`
  },
  playoutZoomClose: function () {
    return `${this.pageName}-zoom-close`
  },
  playoutZoomCreatedEventSave: function () {
    return `${this.pageName}-zoomCreatedEvent-save`
  },
  playoutZoomCreatedBoxClose: function () {
    return `${this.pageName}-zoomCreatedBox-close`
  },
  playoutZoomCreatedBoxOpen: function () {
    return `${this.pageName}-zoomCreatedBox-open`
  },
  playoutZoomColorOpen: function () {
    return `${this.pageName}-zoomColor-open`
  },
  playoutZoomColorChange: function () {
    return `${this.pageName}-zoomColor-change`
  },
  playoutEventColorChange: function () {
    return `${this.pageName}-eventColor-Change`
  },
  playoutEventColorOpen: function () {
    return `${this.pageName}-eventColor-open`
  },

  bookingFileAdd: function () {
    return `${this.pageName}-file-add`
  },
  bookingFileDelete: function () {
    return `${this.pageName}-file-delete`
  },
  bookingFileSearchOpen: function () {
    return `${this.pageName}-fileSearch-open`
  },
  bookingFileSearchClose: function () {
    return `${this.pageName}-fileSearch-close`
  },
  bookingScteSearchClose: function () {
    return `${this.pageName}-scteSearch-close`
  },
  bookingScteSearchOpen: function () {
    return `${this.pageName}-scteSearch-open`
  },
  bookingSourceSearchClose: function () {
    return `${this.pageName}-sourceSearch-close`
  },
  bookingSourceSearchOpen: function () {
    return `${this.pageName}-sourceSearch-open`
  },
  bookingAddExt: function () {
    return `${this.pageName}-ext-add`
  },
  bookingLoadFile: function () {
    return `${this.pageName}-file-load`
  },
  bookingFileEditDelete: function () {
    return `${this.pageName}-file-edit-delete`
  },
  bookingVideoAdd: function () {
    return `${this.pageName}-video-add`
  },
  bookingVideoCancel: function () {
    return `${this.pageName}-video-cancel`
  }
}
export const newHome = {
  // copy from mysource
  newHomemySourceStatusOpen: 'Home-MySource-status-open',
  newHomemySourceStatusClose: 'Home-MySource-status-close',
  newHomemySourceSearch: 'Home-MySource-searchSource',
  newHomemySourceBookmarkOpen: 'Home-MySource-bookmark-open',
  // status
  newHomemySourceStatusAllSelected: 'Home-MySource-statusAll-selected',
  newHomemySourceStatusAllUnselect: 'Home-MySource-statusAll-unselect',
  newHomemySourceStatusonlineSelected: 'Home-MySource-statusOnline-selected',
  newHomemySourceStatusonlineUnselect: 'Home-MySource-statusOnline-unselect',
  newHomemySourceStatusnoCameraSelected:
    'Home-MySource-statusNoCamera-selected',
  newHomemySourceStatusnoCameraUnselect:
    'Home-MySource-statusNoCamera-unselect',
  newHomemySourceStatuslivingSelected: 'Home-MySource-statusLiving-selected',
  newHomemySourceStatuslivingUnselect: 'Home-MySource-statusLiving-unselect',
  newHomemySourceStatusofflineSelected: 'Home-MySource-statusOffline-selected',
  newHomemySourceStatusofflineUnselect: 'Home-MySource-statusOffline-unselect',
  // type
  newHomemySourceTypeAllSelected: 'Home-MySource-typeAll-selected',
  newHomemySourceTypeAllUnselect: 'Home-MySource-typeAll-unselect',
  newHomemySourceTypepackSelected: 'Home-MySource-typePack-selected',
  newHomemySourceTypepackUnselect: 'Home-MySource-typePack-unselect',
  newHomemySourceTypeanywhereSelected: 'Home-MySource-typeAnywhere-selected',
  newHomemySourceTypeanywhereUnselect: 'Home-MySource-typeAnywhere-unselect',
  newHomemySourceTypegridPackSelected: 'Home-MySource-typeGridPack-selected',
  newHomemySourceTypegridPackUnselect: 'Home-MySource-typeGridPack-unselect',
  newHomemySourceTypegridSdiSelected: 'Home-MySource-typeGridSdi-selected',
  newHomemySourceTypegridSdiUnselect: 'Home-MySource-typeGridSdi-unselect',
  newHomemySourceTypeextSelected: 'Home-MySource-typeExt-selected',
  newHomemySourceTypeextUnselect: 'Home-MySource-typeExt-unselect',
  newHomemySourceTypegridViaTokenSelected:
    'Home-MySource-typeGridViaToken-selected',
  newHomemySourceTypegridViaTokenUnselect:
    'Home-MySource-typeGridViaToken-unselect',
  newHomemySourceTypeglobalGridSelected:
    'Home-MySource-typeGlobalGrid-selected',
  newHomemySourceTypeglobalGridUnselect:
    'Home-MySource-typeGlobalGrid-unselect',
  // sort
  newHomemySourceSortonlineFirst: 'Home-MySource-sortOnlineFirst',
  newHomemySourceSortliveFirst: 'Home-MySource-sortLiveFirst',
  newHomemySourceSortnameFirst: 'Home-MySource-sortNameFirst',
  // add btn
  newHomemySourceAddExtOpen: 'Home-MySource-addExt-open',
  newHomemySourceAddExtClose: 'Home-MySource-addExt-close',
  newHomemySourceSaveExt: 'Home-MySource-saveExt',
  // selected ext type
  newHomemySourceAddExtTypeExt: 'Home-MySource-addExtTypeExt',
  newHomemySourceAddExtTypeSMPTE2022: 'Home-MySource-addExtTypeSMPTE2022',
  newHomemySourceAddExtTypeYouTube: 'Home-MySource-addExtTypeYouTube',
  newHomemySourceAddExtTypeGridToken: 'Home-MySource-addExtTypeGridToken',
  //
  newHomemySourceSourceContent: 'Home-MySource-jumpSourceContent',
  newHomemySourceSourceList: 'Home-MySource-jumpSourceList',
  newHomemySourceSourceMap: 'Home-MySource-jumpSourceMap',
  // live or stop
  newHomemySourceBtnLive: 'Home-MySource-takeBtn-live',
  newHomemySourceBtnStop: 'Home-MySource-stopBtn-stop',
  // config-T
  newHomemySourceConfitT: 'Home-MySource-configT',
  // setting box
  newHomemySourceSelect: 'Home-MySource-select-model',
  newHomemySourceSpeed: 'Home-MySource-selectQuality-6',
  newHomemySourceDynamic: 'Home-MySource-setDelay-dynamic',
  newHomemySourceFixed: 'Home-MySource-setDelay-fixed',
  newHomemySourcevbr: 'Home-MySource-setBitrate-vbr',
  newHomemySourcecbr: 'Home-MySource-setBitrate-cbr',
  // set model
  newHomemySourceModelInterview: 'Home-MySource-model-interview',
  newHomemySourceModelNormal: 'Home-MySource-model-normal',
  newHomemySourceModelFastMoving: 'Home-MySource-model-fastMoving',
  newHomemySourceModelSD: 'Home-MySource-model-sd',
  newHomemySourceModelTapeFeed: 'Home-MySource-model-tapeFeed',
  newHomemySourceModelUser1: 'Home-MySource-model-user1',
  newHomemySourceModelUser2: 'Home-MySource-model-user2retretret',
  // speed
  'mySourceSpeed1.2Mb/s': 'Home-MySource-searchSource-1.2Mb/s',
  'mySourceSpeed2.4Mb/s': 'Home-MySource-searchSource-2.4Mb/s',
  'mySourceSpeed6Mb/s': 'Home-MySource-searchSource-6Mb/s',
  'mySourceSpeed12Mb/s': 'Home-MySource-searchSource-12Mb/s',
  'mySourceSpeed24Mb/s': 'Home-MySource-searchSource-24Mb/s',
  'mySourceSpeed30Mb/s': 'Home-MySource-searchSource-30Mb/s',
  'mySourceSpeed50Mb/s': 'Home-MySource-searchSource-50Mb/s',
  'mySourceSpeed100Mb/s': 'Home-MySource-searchSource-100Mb/s',
  'mySourceSpeed120Mb/s': 'Home-MySource-searchSource-120Mb/s',
  'mySourceSpeed150Mb/s': 'Home-MySource-searchSource-150Mb/s',
  'mySourceSpeed180Mb/s': 'Home-MySource-searchSource-180Mb/s',
  // voip
  newHomemySourceVoipStart: 'Home-MySource-voip-start',
  newHomemySourceVoipStop: 'Home-MySource-voip-stop',
  // left R
  RSearch: 'Home-Receiver-search',
  /*
   * SSearch: 'Home-Source-search', 修改为 New-home-MySource-searchSource
   * Receiver状态筛选
   */
  RStatusAvailable: 'Home-Receiver-status-available',
  RStatusUnAvailable: 'Home-Receiver-status-unavailable',
  RStatusLive: 'Home-Receiver-status-Live',
  RStatusUnLive: 'Home-Receiver-status-unLive',
  RStatusOffline: 'Home-Receiver-status-offline',
  RStatusOnline: 'Home-Receiver-status-online',
  RStatusUnOnline: 'Home-Receiver-status-unonline',
  RStatusUnOffline: 'Home-Receiver-status-unoffline',
  RStatusSort: 'Home-Receiver-status-sort',
  RStatusUnSort: 'Home-Receiver-status-unsort',
  RScrol: 'Home-RList-scrol',
  RShowVideo: 'Home-RList-showVideo',
  RHideVideo: 'Home-RList-hideVideo',
  RStopLive: 'Home-RList-stopLive',
  SourceSHowVideo: 'Home-SourceList-showVideo',
  SourceHideVideo: 'Home-SourceList-hideVideo',
  // video
  newHomemySourceVideoClose: 'Home-MySource-video-close',
  newHomemySourceVideoOpen: 'Home-MySource-video-open',
  // anywhere camera相关
  newHomeOpenCamera: 'Home-AnywhereCamera-open',
  newHomeCloseCamera: 'Home-AnywhereCamera-close',
  newHomeCameraManual: 'Home-AnywhereCamera-manual',
  newHomeCameraAuto: 'Home-AnywhereCamera-auto',
  newHomeCameraReset: 'Home-AnywhereCamera-reset',
  // video control
  newHomeVideoControlRInfoOpen: 'Home-videoControl-rInfo-open',
  newHomeVideoControlRInfoClose: 'Home-videoControl-rInfo-close',
  newHomeVideoControlChangeR: 'Home-videoControl-changeR',
  newHomeVideoControlChangeSource: 'Home-videoControl-changeSource',
  newHomeVideoControlStop: 'Home-videoControl-live-stop',
  newHomeVideoControlStart: 'Home-videoControl-live-start',
  newHomeVideoControlIpStremOutOpen: 'Home-videoControl-IpStremOut-open',
  newHomeVideoControlIpStremOutClose: 'Home-videoControl-IpStremOut-close',
  newHomeVideoControlChangeBitrate: 'Home-videoControl-changeBitrate',
  newHomeVideoControlChangeDelay: 'Home-videoControl-changeDelay',
  newHomeVideoControlChangeQulity: 'Home-videoControl-changeQulity',
  newHomeVideoControlConfigTOpen: 'Home-videoControl-configT-open',
  newHomeVideoControlCameraOpen: 'Home-videoControl-camera-open',
  newHomeVideoControlCameraClose: 'Home-videoControl-camera-close',
  // multi-control
  showMultiControlHM: 'Home-showMultiControl',
  setTimeLockHM: 'Home-setTimelock',
  stopTimeLockHM: 'Home-stopTimelock',
  setTimeLockBitrateBySliderHM: 'Home-multiContorl-setBitrate-bySilder',
  setTimeLockDelayBySilderHM: 'Home-multiContorl-setDelay-bySilder',
  setTimeLockBitrateByInputHM: 'Home-multiContorl-setBitrate-byInput',
  setTimeLockDelayByInputHM: 'Home-multiContorl-setDelay-byInput',
  selectTimeLockReceiverByDropHM: 'Home-multiContorl-selectReceiver-byDrop',
  cancelSelectTimeLockReceiverByDropHM:
    'Home-multiContorl-cancelSelectReceiver-byDrop',
  cancelSelectTimLockReceiverByClickBtnHM:
    'Home-multiContorl-cancelSelectReceiver-byClickBtn',
  showWebPageHM: 'Home-multiContorl-showWebR',
  showReceiverInfoHM: 'Home-multiContorl-showReceiverInfo',
  clickMuteButtonHM: 'Home-multiContorl-clickMuteButton',
  clickVoiceButtonHM: 'Home-multiContorl-clickVoiceButton',
  selectSourceHM: 'Home-multiContorl-clickVoiceButton',
  clickStartButtonHM: 'Home-multiContorl-startLive',
  clickStopButtonHM: 'Home-multiContorl-stopLive',
  closeMultiControlHM: 'Home-multiContorl-close',
  // ip stream out
  enableEncoderHM: 'Home-ipStreamOut-encoderEnable',
  disableEncoderHM: 'Home-ipStreamOut-encoderDisable',
  enableCbrHM: 'Home-ipStreamOut-cbrEnable',
  disableCbrHM: 'Home-ipStreamOut-cbrDisable',
  setResolutionHM: 'Home-ipStreamOut-setResolution',
  setBitrateHM: 'Home-ipStreamOut-setBitrate',
  setABitrateHM: 'Home-ipStreamOut-setABitrate',
  setAChannelHM: 'Home-ipStreamOut-setAChannel',
  setCodecHM: 'Home-ipStreamOut-setCodec',
  setCopierHM: 'Home-ipStreamOut-setFileCopier',
  addNewShareAccountHM: 'Home-ipStreamOut-addNewShareAccount',
  startPushStreamHM: 'Home-ipStreamOut-pushStream',
  stopPushStreamHM: 'Home-ipStreamOut-stopPushStream',
  uploadLogoHM: 'Home-ipStreamOut-uploadLogo',
  SharedMemoryHM: 'Home-ipStreamOut-SharedMemory',
  // media
  newHomeOpenShare: 'Home-share-open',
  newHomeCloseShare: 'Home-share-close',
  // home表格
  newHomeTableBitrate: 'Home-Table-bitrate',
  newHomeTableLatency: 'Home-Table-latency',
  newHomeTableFormat: 'Home-Table-format',
  newHomeTableBattery: 'Home-Table-battery',
  newHomeTableBookmark: 'Home-Table-bookmark',
  newHomeTableTypeVal: 'Home-Table-type',
  newHomeTableVersion: 'Home-Table-version',
  newHomeTableLocation: 'Home-Table-location',
  // 表格比特率延迟显示
  newHomeTableBiterShow: 'Home-Table-BiterShow',
  newHomeTableSetBiterVbr: 'Home-Table-SetBiterVbr',
  newHomeTableSetBiterCbr: 'Home-Table-SetBiterCbr',
  newHomeTableSetBiterVal: 'Home-Table-SetBiterVal',
  newHomeTableDelayShow: 'Home-Table-DelayShow',
  newHomeTableSetDelayDynamic: 'Home-Table-SetDelayDynamic',
  newHomeTableSetDelayFixed: 'Home-Table-SetDelayFixed',
  newHomeTableSetDelayVal: 'Home-Table-SetDelayVal',
  newHomeTableShowBookmark: 'Home-Table-ShowBookmark',
  newHomeTableCloseBookmark: 'Home-Table-CloseBookmark',
  newHomeTableShowVideo: 'Home-Table-ShowVideo',
  newHomeTableSelectR: 'Home-Table-SelectR',
  newHomeTableDragColumn: 'Home-Table-DragColumn',
  newHomeTableColumnStretch: 'Home-Table-ColumnStretch',
  newHomeTableBookmarkSave: 'Home-Table-BookmarkSave'
}

export const token = {
  pageName: 'Token',
  tokenCreateToken: function () {
    return `${this.pageName}-createToken`
  },
  tokenEditToken: function () {
    return `${this.pageName}-editToken`
  },
  tokenDeleteToken: function () {
    return `${this.pageName}-deleteToken`
  },
  /*
   * tokenGridSendEmail: function () {
   *     return `${this.pageName}-gridSendEmail`
   * },
   */
  tokenSendEmail: function () {
    return `${this.pageName}-sendEmail`
  },
  tokenAddDevice: function () {
    return `${this.pageName}-addDevice`
  },
  tokenRemoveDevice: function () {
    return `${this.pageName}-removeDevice`
  },
  tokenAddAllDevice: function () {
    return `${this.pageName}-addAllDevice`
  },
  tokenRemoveAllDevice: function () {
    return `${this.pageName}-removeAllDevice`
  },
  tokenSavePairingDetails: function () {
    return `${this.pageName}-savePairingDetails`
  },
  tokenItemsList: function () {
    return `${this.pageName}-itemsList`
  },
  /*
   * tokenDeleteDeviceList: function () {
   *     return `${this.pageName}-deleteDeviceList`
   * },
   * tokenPermanentPairing: function () {
   *     return `${this.pageName}-permanentPairing`
   * },
   */
  tokenAllSelectedDevice: function () {
    return `${this.pageName}-allSelectedDevice`
  },
  tokenAcceptRequest: function () {
    return `${this.pageName}-acceptRequest`
  },
  tokenDenyRequest: function () {
    return `${this.pageName}-denyRequest`
  },
  tokenDeleteRequest: function () {
    return `${this.pageName}-deleteRequest`
  },
  tokenExpireRequest: function () {
    return `${this.pageName}-expiredRequest`
  },
  tokenEditDeviceName: function () {
    return `${this.pageName}-editDeviceName`
  }
  /*
   * tokenAnyWherePage: function () {
   *     return `${this.pageName}-anyWherePage`
   * },
   * tokenLoadMoreDevice: function () {
   *     return `${this.pageName}-loadMoreDevice`
   * },
   */
}

export const multiview = {
  multiviewSelectVoipDeviceOpen: 'Multiview-SelectVoipDevice-Open', // 选择voip通话设备弹窗
  multiviewSelectVoipDeviceClose: 'Multiview-SelectVoipDevice-Close',
  multiviewAddReceiver: 'Multiview-Add-Receiver', // 添加设备
  multiviewRemoveReceiver: 'Multiview-Remove-Receiver',
  multiviewBookmarkClose: 'Multiview-Bookmark-Close', // 关闭bookmark选择框
  multiviewBookmarkOpen: 'Multiview-Bookmark-Open', //
  multiviewShowInfoOpen: 'Multiview-ShowInfo-Open', // showInfo按钮开启
  multiviewShowInfoClose: 'Multiview-ShowInfo-Close',
  multiviewSpliteScreen4: 'Multiview-Screen-4', // 当前分屏数量
  multiviewSpliteScreen6: 'Multiview-Screen-6',
  multiviewSpliteScreen9: 'Multiview-Screen-9',
  multiviewSpliteScreen16: 'Multiview-Screen-16',
  multiviewSpliteScreen25: 'Multiview-Screen-25',
  multiviewFullScreenOpen: 'Multiview-FullScreen-Open', // 全屏(隐藏头部)
  multiviewFullScreenClose: 'Multiview-FullScreen-Close',
  multiviewVoipStart: 'Multiview-Voip-Start', // 开始通话
  multiviewVoipReStart: 'Multiview-Voip-ReStart', // 继续通话
  multiviewVoipRefresh: 'Multiview-Voip-ReStart', // 重连 刷新voip
  multiviewAllVoipClose: 'Multiview-AllVoip-Close', // 取消全部voip
  multiviewVideoFullScreenClose: 'Multiview-VideoFullScreen-Close', // video视频关闭全屏
  multiviewVideoFullScreenOpen: 'Multiview-VideoFullScreen-Open',
  multiviewVideoAudioOpen: 'Multiview-VideoAudio-Open', // 视频声音开启
  multiviewVideoAudioClose: 'Multiview-VideoAudio-Close',
  multiviewVideoRefresh: 'Multiview-Video-Refresh', // 刷新视频
  multiviewGoToNewMultiview: 'Multiview-NewMultiview-Goto'
}

export const multiviewPlus = {
  mvpPageToAll: 'mvpMultiview-Page-All',
  mvpPageToWorkpace: 'mvpMultiview-Page-Workpace',
  mvpPageToManage: 'mvpMultiview-Page-Manage',
  mvpShowDescribleOpen: 'mvpMultiview-ShowDescribe-on',
  mvpShowDescribleClose: 'mvpMultiview-ShowDescribe-on',
  mvpSpliteScreen4: 'mvpMultiview-Screen-4', // 当前分屏数量
  mvpSpliteScreen6: 'mvpMultiview-Screen-6',
  mvpSpliteScreen9: 'mvpMultiview-Screen-9',
  mvpSpliteScreen16: 'mvpMultiview-Screen-16',
  mvpSpliteScreen25: 'mvpMultiview-Screen-25',
  mvpFullScreenOpen: 'mvpMultiview-FullScreen-Open', // 全屏(隐藏头部)
  mvpFullScreenClose: 'mvpMultiview-FullScreen-Close',
  mvpAllDeviceSelectAll: 'mvpMultiview-AllDevice-SelectAll-Select',
  mvpAllDeviceSelectCancel: 'mvpMultiview-AllDevice-SelectAll-Cancel',
  mvpWorkspaceSelectAll: 'mvpMultiview-Workspace-SelectAll-Select',
  mvpWorkspaceSelectCancel: 'mvpMultiview-Workspace-SelectAll-Cancel',
  mvpAllLiveSelected: 'mvpMultiview-AllLive-Selected',
  mvpAllLiveUnSelected: 'mvpMultiview-AllLive-UnSelected',
  mvpAllOnlineSelected: 'mvpMultiview-AllOnline-UnSelected',
  mvpAllOnlineUnSelected: 'mvpMultiview-AllOnline-Selected',
  mvpAllOfflineUnSelected: 'mvpMultiview-AllOffline-UnSelected',
  mvpAllOfflineSelected: 'mvpMultiview-AllOffline-Selected',
  mvpWorkspaceLiveSelected: 'mvpMultiview-AllLive-Selected',
  mvpWorkspaceLiveUnSelected: 'mvpMultiview-AllLive-UnSelected',
  mvpWorkspaceOnlineSelected: 'mvpMultiview-AllOnline-UnSelected',
  mvpWorkspaceOnlineUnSelected: 'mvpMultiview-AllOnline-Selected',
  mvpWorkspaceOfflineUnSelected: 'mvpMultiview-AllOffline-UnSelected',
  mvpWorkspaceOfflineSelected: 'mvpMultiview-AllOffline-Selected',
  mvpWorkspaceAdd: 'mvpMultiview-Workspace-Add',
  mvpWorkspaceEdit: 'mvpMultiview-Workspace-Edit',
  mvpWorkspaceDuplicate: 'mvpMultiview-Workspace-Duplicate',
  mvpSortName: 'mvpMultiview-Sort-Name',
  mvpSortUnName: 'mvpMultiview-Sort-UnName',
  multiviewGoToMultiview: 'Multiview-Multiview-Goto'
}

export const setting = {
  // 切换tab
  SettingTabExternalSource: 'Setting-Tab-ExternalSource',
  SettingTabBookmark: 'Setting-Tab-Bookmark',
  SettingTabAccount: 'Setting-Tab-Account',
  SettingTabDevice: 'Setting-Tab-Device',
  SettingTabAccessAPP: 'Setting-Tab-AccessAPP',
  SettingTabSalesForce: 'Setting-Tab-SalesForce',
  SettingTabMenuList: 'Setting-Tab-MenuList',
  SettingTabLicensed: 'Setting-Tab-Licensed',
  SettingTabFeatureList: 'Setting-Tab-FeatureList',
  SettingTabMailTemplate: 'Setting-Tab-MailTemplate',
  SettingTabDeviceChangeLog: 'Setting-Tab-DeviceChangeLog',
  SettingTabTestInstance: 'Setting-Tab-TestInstance',
  SettingTabSocialAccount: 'Setting-Tab-SocialAccount',
  SettingTabVirtualCloudR: 'Setting-Tab-VirtualCloudR',
  SettingTabGroupManage: 'Setting-Tab-GroupManage',

  SettingTabAccountAdd: 'SettingTab-Account-Add',
  SettingTabAccountAddSave: 'SettingTab-Account-AddSave',
  SettingTabAccountModify: 'SettingTab-Account-Modify',
  SettingTabAccountModifySave: 'SettingTab-Account-ModifySave',
  SettingTabAccountInactive: 'SettingTab-Account-Inactive',
  SettingTabAccountActive: 'SettingTab-Account-Active',
  SettingTabAccountDelete: 'SettingTab-Account-Delete',
  SettingTabAccountMonitor: 'SettingTab-Account-Monitor',
  SettingTabAccountIncreaseAccount: 'SettingTabAccount-IncreaseAccount',
  SettingTabAccountDegradeRole: 'SettingTab-Account-DegradeRole',
  SettingTabAccountChangeOwner: 'SettingTab-Account-ChangeOwner',
  SettingTabAccountReplaceEmail: 'SettingTab-Account-ReplaceEmail',
  SettingTabAccountBatchReloadAdd: 'SettingTab-Account-BatchReloadAdd',
  SettingTabAccountBatchReloadRemove: 'SettingTab-Account-BatchReloadRemove',
  SettingTabAccountSingleReloadAdd: 'SettingTab-Account-SingleReloadAdd',
  SettingTabAccountSingleReloadRemove: 'SettingTab-Account-SingleReloadRemove',
  SettingTabAccountRoleManagement: 'SettingTab-Account-RoleManagement',
  SettingTabAccountExport: 'SettingTab-Account-Export',
  SettingTabAccountRoleAdd: 'SettingTab-Account-RoleAdd',
  SettingTabAccountRoleModify: 'SettingTab-Account-RoleModify',
  SettingTabAccountRoleDelete: 'SettingTab-Account-RoleDelete',
  SettingTabAccountRoleAccountsAdd: 'SettingTab-Account-RoleAccountsAdd',
  SettingTabAccountRoleAccountsRemove: 'SettingTab-Account-RoleAccountsRemove',
  SettingTabAccountRelationManageSave: 'SettingTab-Account-RelationManageSave',
  SettingTabAccountRelationManagePreviousMethod:
    'SettingTab-Account-RelationManagePreviousMethod',
  SettingTabAccountRelationManageNewMethod:
    'SettingTab-Account-RelationManageNewMethod'
}

export const alert = {
  pageName: 'Alert',
  alertReceiveOpen: function () {
    return `${this.pageName}-inbox-open`
  },
  alertSentOpen: function () {
    return `${this.pageName}-sent-open`
  },
  alertComposeOpen: function () {
    return `${this.pageName}-compose-open`
  },
  alertComposeClose: function () {
    return `${this.pageName}-compose-close`
  },
  alertComposeSend: function () {
    return `${this.pageName}-compose-send`
  },
  alertComposeSearchClick: function () {
    return `${this.pageName}-composeSearch-click`
  },
  alertDeleteClick: function () {
    return `${this.pageName}-delete-click`
  },
  alertInboxMessageClick: function () {
    return `${this.pageName}-inboxMessage-click`
  },
  alertSentMessageClick: function () {
    return `${this.pageName}-sentMessage-click`
  },
  alertComposeFilterClick: function () {
    return `${this.pageName}-composeFilter-click`
  },
  alertComposeBookmarkClick: function () {
    return `${this.pageName}-composeBookmark-click`
  },
  alertBookmarkDeviceListClick: function () {
    return `${this.pageName}-bookmarkDeviceList-click`
  }
}

export const routerMonitor = {
  pageName: 'routerMonitor',
  routerMonitorSpeedChange: function () {
    return `${this.pageName}-speed-select`
  },
  routerMonitorSlotSpeedChange: function () {
    return `${this.pageName}-slotSpeed-select`
  },
  routerMonitorTListOpen: function () {
    return `${this.pageName}-TList-open`
  },
  routerMonitorTChange: function () {
    return `${this.pageName}-T-select`
  },
  routerMonitorConfigTOpen: function () {
    return `${this.pageName}-configT-open`
  },
  routerMonitorDashboardOpen: function () {
    return `${this.pageName}-dashboard-open`
  },
  routerMonitorSlotStartClick: function () {
    return `${this.pageName}-slotStart-click`
  },
  routerMonitorSlotStopClick: function () {
    return `${this.pageName}-slotStop-click`
  },
  routerMonitorSlotResetClick: function () {
    return `${this.pageName}-slotReset-click`
  }
}
